/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
import React from 'react';
import { Link } from 'react-router-dom';
import Editor from '@monaco-editor/react';
import Button from '../../Components/Button';
import Input from '../../Components/Input';
import { BuilderActionType, BuilderContext } from './state';
import VendexAction from '../../Model/VendexAction';
import IconButton from '../../Components/IconButton';
import { Trash2 } from '../../Components/Icon';
import Select from '../../Components/Select';

type Props = {
    actions?: VendexAction[];
    onChange: (actions: VendexAction[]) => void;
    defaultTrigger?: string;
};

const defaultFunctions = [
    'CHANGE_PAGE',
    'SELECT_PRODUCT',
    'PRODUCT_VEND',
    'SET_DATA',
    'EMAIL_IS_VALID',
    'PHONE_IS_VALID',
    'ZIP_IS_VALID',
    'HAS_VALUE',
];

type ActionProps = {
    action?: VendexAction;
    onChange: (value: VendexAction) => void;
    handleRemove?: () => void;
    isCustomNew?: boolean;
    onClose?: () => void;
    products: { name: string; id: string }[];
    defaultTrigger?: string;
};

function Action({
    action,
    onChange,
    products,
    onClose,
    handleRemove,
    defaultTrigger,
    isCustomNew = false,
}: ActionProps) {
    const [_action, setAction] = React.useState(action);
    const isNew = !action;
    const [isCustom, setIsCustom] = React.useState(isCustomNew);
    const { pages, scripts } = React.useContext(BuilderContext).state;
    const { dispatch } = React.useContext(BuilderContext);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const editorRef = React.useRef<any>(null);

    React.useEffect(() => {
        if (action) {
            const temp = defaultFunctions.includes(action.type);
            setIsCustom(!temp);
        }
    }, [action]);

    const getValue = () => {
        let newString = 'payload => {\n\treturn true;\n }';
        if (_action?.type) {
            if (scripts && scripts[_action.type]) {
                newString = scripts[_action.type]?.toString() as string;
            }
        }
        return newString;
    };

    React.useEffect(() => {
        if (editorRef.current) {
            editorRef.current.setValue(getValue());
        }
    }, [_action?.type, isCustom]);

    const isPayloadRequired = (value?: string) => {
        if (value)
            return ['SELECT_PRODUCT', 'SET_DATA', 'CHANGE_PAGE'].includes(
                value,
            );
        return false;
    };

    const isValid = () => {
        if (_action) {
            if (!_action.type) return false;
            if (isPayloadRequired(_action.type) && !_action.payload)
                return false;
            return true;
        }
        return false;
    };

    const handleTypeChange = (value: string) => {
        let payload;
        if (value === 'SET_DATA') {
            payload = `PROPERTY, VALUE`;
        }
        setAction({ ..._action, type: value, payload });
    };

    const handlePayload = (value: string) => {
        setAction({ ...(_action as VendexAction), payload: value });
    };

    const handleFunctionSave = () => {
        if (editorRef.current && _action?.type) {
            const val = editorRef.current.getValue() as string;
            const newScripts = scripts ? { ...scripts } : {};
            newScripts[_action?.type] = val;
            dispatch({
                type: BuilderActionType.SET_SCRIPTS,
                payload: newScripts,
            });
        }
    };

    return (
        <div className="mt-6 pt-4 shadow-lg p-4 bg-slate-50 rounded-md relative">
            {handleRemove && (
                <div className="absolute right-3 top-3 flex gap-3">
                    <IconButton
                        onClick={handleRemove}
                        className="text-red-600"
                        btnSize="small"
                    >
                        <Trash2 />
                    </IconButton>
                </div>
            )}
            <div className="grid grid-cols-2 gap-4 my-2">
                <div>
                    <p>Type</p>
                    {!isCustom ? (
                        <Select
                            value={_action?.type}
                            onChange={(e) => handleTypeChange(e.target.value)}
                            className="w-full px-2 py-3 my-2 rounded-sm box-border"
                        >
                            <option value="">SELECT ACTION</option>
                            {defaultFunctions.map((el) => (
                                <option key={el} value={el}>
                                    {el}
                                </option>
                            ))}
                        </Select>
                    ) : (
                        <Input
                            placeholder="Provide a name"
                            onChange={(e) => handleTypeChange(e.target.value)}
                            value={_action?.type ? _action.type : ''}
                        />
                    )}
                </div>
                {!defaultTrigger && (
                    <div>
                        <p>Trigger</p>
                        <Select
                            value={_action?.trigger}
                            onChange={(e) =>
                                setAction({
                                    ...(_action as VendexAction),
                                    [e.target.name]: e.target.value,
                                })
                            }
                            className="w-full px-2 py-3 my-2 rounded-sm box-border"
                            name="trigger"
                        >
                            <option value="click">click</option>
                            <option value="focus">focus</option>
                            <option value="keypress">keypress</option>
                        </Select>
                    </div>
                )}
            </div>
            {_action?.type !== 'PRODUCT_VEND' && (
                <>
                    <p>Payload</p>
                    <p className="text-xs text-gray-500">
                        Pass value to function if required
                    </p>
                    {_action?.type !== 'CHANGE_PAGE' &&
                        _action?.type !== 'SELECT_PRODUCT' && (
                            <Input
                                value={_action?.payload ? _action?.payload : ''}
                                onChange={(e) => handlePayload(e.target.value)}
                            />
                        )}
                    {_action?.type === 'SELECT_PRODUCT' && (
                        <Select
                            value={_action?.payload ? _action?.payload : ''}
                            onChange={(e) => handlePayload(e.target.value)}
                            className="w-full px-2 py-3 my-2 rounded-sm box-border"
                        >
                            <option value="">SELECT PRODUCT</option>
                            {products.map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.name}
                                </option>
                            ))}
                        </Select>
                    )}
                    {_action?.type === 'CHANGE_PAGE' && (
                        <Select
                            value={_action?.payload ? _action?.payload : ''}
                            onChange={(e) => handlePayload(e.target.value)}
                            className="w-full px-2 py-3 my-2 rounded-sm box-border"
                        >
                            <option value=""> SELECT PAGE</option>
                            {pages.map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.name}
                                </option>
                            ))}
                        </Select>
                    )}
                </>
            )}
            {isCustom && (
                <>
                    <p>Function</p>
                    <Link
                        to="/documentation"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <p className="text-xs text-gray-500">
                            Read Documentation
                        </p>
                    </Link>
                    <br />
                    <Editor
                        theme="vs-dark"
                        width="100%"
                        height="320px"
                        defaultLanguage="javascript"
                        defaultValue={getValue()}
                        // eslint-disable-next-line no-return-assign
                        onMount={(ref) => (editorRef.current = ref)}
                    />
                </>
            )}
            <div className="flex gap-2 justify-end mt-2">
                <Button
                    onClick={() => {
                        setAction(action);
                        if (onClose) onClose();
                    }}
                    disabled={action === _action && !isNew}
                    className="bg-primary text-white py-1 text-xs"
                >
                    Cancel
                </Button>
                <Button
                    disabled={!isValid()}
                    onClick={() => {
                        onChange(_action as VendexAction);
                        handleFunctionSave();
                        if (onClose) onClose();
                    }}
                    className="bg-primary text-white py-1 text-xs"
                >
                    {isNew ? 'Add' : 'Update'}
                </Button>
            </div>
        </div>
    );
}

function ActionSelector({ onChange, actions = [], defaultTrigger }: Props) {
    const [newAction, setNewAction] = React.useState(0);

    const products = window.DATA?.products ?? [];

    const handleRemove = (index: number) => {
        const temp = [...actions];
        temp.splice(index, 1);
        onChange(temp);
    };

    const handleAdd = (value: VendexAction) => {
        if (defaultTrigger) {
            value.trigger = defaultTrigger as any;
        }
        const temp = [...actions];
        temp.push(value);
        onChange(temp);
    };

    const handleChange = (index: number, action: VendexAction) => {
        const temp = [...actions];
        temp[index] = action;
        onChange(temp);
    };

    return (
        <div className="mt-6 pt-4">
            <p>
                <strong>ACTIONS</strong>
            </p>
            {actions.map((el, index) => (
                <Action
                    key={`${el.type}${index.toString()}`}
                    onChange={(value) => handleChange(index, value)}
                    action={el}
                    products={products}
                    defaultTrigger={defaultTrigger}
                    handleRemove={() => handleRemove(index)}
                />
            ))}
            {newAction !== 0 ? (
                <Action
                    onChange={(value) => handleAdd(value)}
                    products={products}
                    isCustomNew={newAction === 2}
                    onClose={() => setNewAction(0)}
                    defaultTrigger={defaultTrigger}
                />
            ) : (
                <div className="flex gap-2 justify-end mt-4">
                    <Button
                        onClick={() => setNewAction(2)}
                        className="bg-secondary text-white"
                    >
                        Add New Action
                    </Button>
                    <Button
                        onClick={() => setNewAction(1)}
                        className="bg-secondary text-white"
                    >
                        Add Action
                    </Button>
                </div>
            )}
        </div>
    );
}
export default ActionSelector;

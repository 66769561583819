import React from 'react';
import Modal from '../../Components/Modal';
import Build from '../../Model/Build';
import { ApplicationService, ExperienceService } from '../../api';
import Select from '../../Components/Select';
import Button from '../../Components/Button';
import { useNotification } from '../../Components/Notification';

interface Props {
    onClose: () => void;
    builderVersion: string;
    version: string;
    experienceId: string;
    refreshData: () => void;
}
function AdvancedSettings({
    onClose,
    builderVersion,
    version,
    experienceId,
    refreshData,
}: Props) {
    const [builds, setBuilds] = React.useState<Build[]>([]);
    const [latestBuild, setLatestBuild] = React.useState<Build>();
    const [selected, setSelected] = React.useState(builderVersion);
    const notification = useNotification();

    const getData = () => {
        ApplicationService.GetAllBuilds().then((response) => {
            setBuilds(response);
        });
    };

    const handleChange = () => {
        ExperienceService.UpdateVendExpBuilder(experienceId, selected)
            .then(() => {
                refreshData();
                onClose();
                notification({
                    type: 'success',
                    message: 'successfully propagated changes',
                    duration: 6000,
                });
            })
            .catch(() =>
                notification({
                    type: 'error',
                    message: 'failed to update builder version',
                    duration: 6000,
                }),
            );
    };

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        const temp = builds.find((el) => el.isLatest);
        setLatestBuild(temp);
    }, [builds]);

    return (
        <Modal open close={onClose} className="max-w-xl">
            <p className="text-xl font-semibold primary">ADVANCED SETTINGS</p>
            <br />
            <p>
                <strong>VERSION: </strong>
                {version}
            </p>
            <p>
                <strong>BUILDER VERSION: </strong>
                {builderVersion}
            </p>
            <p>
                <strong>LATEST BUILDER VERSION: </strong>
                {latestBuild?.versionNumber}
            </p>
            <br />
            <p className="text-xs">
                Changes to builder version may cause the experience to be buggy.
                Please make sure the experience is working properly before
                proceeding.
            </p>
            <Select
                value={selected}
                label="Change builder version"
                onChange={(e) => setSelected(e.target.value)}
            >
                <option>SELECT VERSION</option>
                {builds.map((el) => (
                    <option key={el.versionNumber} value={el.versionNumber}>
                        {el.versionNumber}
                        {el.versionNumber ===
                            process.env.REACT_APP_LEGACY_VERSION && (
                            <span className="text-blue-600">
                                - LEGACY VERSION
                            </span>
                        )}
                    </option>
                ))}
            </Select>
            <Button
                onClick={handleChange}
                disabled={version === selected}
                className="float-right bg-red-600 text-white"
            >
                Confirm Change
            </Button>
        </Modal>
    );
}

export default AdvancedSettings;

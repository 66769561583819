import { DeviceType } from '../../../Model/VendexDevice';
import PageData, { PageType } from '../../../Model/PageData';
import VendexElement, { VendexElementType } from '../../../Model/VendexElement';
import View from '../../../Model/View';
import VendExp from '../../../Model/VendExp';

export enum BuilderActionType {
    SET_VIEW,
    SET_EXPERIENCE,
    SET_PAGES,
    ADD_PAGE,
    DELETE_PREVIEW_PAGE,
    ADD_ELEMENT,
    SET_CSS,
    SET_SCRIPTS,
    UPDATE_CSS,
    DELETE_CSS,
    UPDATE_ELEMENT,
    DELETE_ELEMENT,
    SET_PREVIEW_PAGE,
    UPDATE_PAGE,
    SET_PAGE_NAME,
    APPLY_CHANGES,
    SET_DEVICE,
    SET_MEDIA_LIBRARY,
    TOGGLE_FONT,
    TOOGLE_ADD_PAGE,
}

export type BuilderAction =
    | { type: BuilderActionType.SET_VIEW; payload: View }
    | { type: BuilderActionType.SET_DEVICE; payload: DeviceType }
    | { type: BuilderActionType.SET_EXPERIENCE; payload: VendExp }
    | { type: BuilderActionType.SET_PAGES; payload: PageData[] }
    | { type: BuilderActionType.SET_CSS; payload: string }
    | {
          type: BuilderActionType.SET_SCRIPTS;
          payload: { [key: string]: string };
      }
    | { type: BuilderActionType.ADD_PAGE, payload?: PageType }
    | {
          type: BuilderActionType.UPDATE_CSS;
          payload: { selector: string; properties: { [key: string]: string } };
      }
    | {
          type: BuilderActionType.DELETE_CSS;
          payload: string;
      }
    | { type: BuilderActionType.DELETE_PREVIEW_PAGE }
    | { type: BuilderActionType.ADD_ELEMENT; payload: VendexElementType }
    | { type: BuilderActionType.UPDATE_ELEMENT; payload: VendexElement }
    | { type: BuilderActionType.DELETE_ELEMENT; payload: string }
    | { type: BuilderActionType.SET_PREVIEW_PAGE; payload: string }
    | { type: BuilderActionType.UPDATE_PAGE }
    | { type: BuilderActionType.SET_PAGE_NAME; payload: string }
    | { type: BuilderActionType.APPLY_CHANGES }
    | {
          type: BuilderActionType.SET_MEDIA_LIBRARY;
          payload?: {
              open: 'MEDIA' | 'UPLOAD';
              onChange?: (url: string) => void;
              selected?: string;
              type?: 'IMAGE' | 'VIDEO' | 'FONT';
          };
      }
    | { type: BuilderActionType.TOGGLE_FONT }
    | { type: BuilderActionType.TOOGLE_ADD_PAGE };
